<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">账户设置</el-breadcrumb-item>
              <el-breadcrumb-item>绑定邮箱</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_right">
                <div class="box_right_top">绑定邮箱</div>
                <div class="box_right_cent">
                    <div class="cent_email flex-center">
                        <div class="cent_email_box">
                            <div class="flex-start email_box">
                                <div class="original_text">邮箱:</div>
                                <el-input class="email_input" v-model.trim="email"></el-input>
                                <div class="email_text">绑定邮箱才能及时收到信息</div>
                            </div>
                            <div class="pass_box flex-center">
                                <el-button type="warning" @click="emailBtn()" size="medium">提 交</el-button>
                            </div>
                        </div>
                        
                    </div>
                  
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { bindEmail } from "@/api/personage/personage";
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        email:''
      }
  },
  created(){
    this.email=this.$route.params.email
  },
  methods:{
    changePassword(){
      this.$router.push({ name: 'ChangePassword' })
    },
    emailBtn(){
      if(this.email == ''){
        this.$message.error('请填写邮箱号')
        return
      }
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if(!reg.test(this.email)){
        this.$message.error('请填写正确的邮箱号')
        return
      }
      let data={
        'email':this.email
      }
      bindEmail(data).then((data) => {
        if(data && data.code=="00000"){
          this.$message({
            type: "success",
            message: data.desc,
          });
          this.email =data.data
          this.$router.push({ name: 'AccountSettings'})
        }else{
          this.$message.error(data.desc)
        }
      })
    },
    goto(){
      this.$router.push({ name: 'AccountSettings'})
    }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_right{
    width: 87%;
    height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .cent_email{
        width: 100%;
        margin-top: 155px;
    }
    .cent_email_box{
        font-size: 16px;
        color: #666666;
    }
    .email_box{
        margin-bottom: 30px;
    }
    .original_text{
        width: 80px;
        margin-left: 55px;
    }
    .email_input{
        width: 60%;
    }
    .email_text{
        width: 300px;
        font-size: 14px;
        margin-left: 10px;
    }
    
  }
}
// 按钮样式
.el-button--warning {
    background: #cd9f49;
    border-color: #cd9f49;
    color: #FFF;
    
    &:hover {
        background: #e0b16a;
        border-color: #e0b16a;
    }
}
</style>